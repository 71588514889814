<template>
    <div class="full-height-layout fill">
        <div class="row">
            <template v-for="(page, key) in pages">
                <div
                    :key="key" v-if="isAllowedTo(page.actionName)"
                    class="col-sm-12 col-md-6 col-lg-4 col-xl-3 full-height-layout">
                    <mercur-card class="fill full-height-layout mx-4 mb-3">
                        <h2 class="font-weight-normal">{{page.title}}</h2>
                        <div class="fill">
                            <p>{{page.description}}</p>
                        </div>
                        <div class="text-right">
                            <mercur-button class="btn text-uppercase" :to="page.route">go to {{page.title}}</mercur-button>
                        </div>
                    </mercur-card>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'LegacyOverview',
    data () {
        return {
            pages: [
                {
                    title: 'Legacy Products',
                    description: `To generate products for the legacy system.`,
                    route: {
                        name: 'LegacyProductsOverview',
                    },
                    actionName: 'getLegacyProducts',
                },
                {
                    title: 'Legacy Attributes',
                    description: `In the Legacy Attributes it is possible to add, edit and map legacy attributes with attributes`,
                    route: {
                        name: 'LegacyAttributesView',
                    },
                },
            ],
        }
    },
}
</script>
